<template>
  <div>
    <v-data-table
      :headers="dessertHeaders"
      :items="newPackages"
      item-key="_id"
      show-expand
      :single-expand="true"
      hide-default-footer
      @page-count="pageCount = $event"
      class="elevation-1"
      :loading="TableLoading"
      loading-text="Loading... Please wait"
      :items-per-page="20"
    >
      <template v-slot:item.shippingFee="{ item }">
        <span>{{ DisplayCurrency(item.shippingFee) }}</span>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
      </template>
      <template v-slot:item.maxKmReach="{ item }">
        <span class="maxKm" v-if="item.maxKmReach">Above Coverage</span>
        <span class="maxKmNot" v-else>Within Coverage</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="text-center display-1">Sender Details</div>
          <v-simple-table dark class="mt-7 mb-7">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Sender Name</th>
                  <th class="text-left">Phone Number</th>
                  <th class="text-left">PickUp Address</th>
                  <th class="text-left">Weight</th>
                </tr>
              </thead>
              <tbody v-if="item.senderDetails">
                <tr>
                  <td>
                    {{ item.senderDetails.firstName }}
                    {{ item.senderDetails.lastName }}
                  </td>
                  <td>{{ item.senderDetails.phoneNumber }}</td>
                  <td>{{ item.pickUpLocation.Street }}</td>
                  <td>{{ item.weight }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td>
                    {{ item.offlineSenderDetails.firstName }}
                    {{ item.offlineSenderDetails.lastName }}
                  </td>
                  <td>{{ item.offlineSenderDetails.phoneNumber }}</td>
                  <td>{{ item.pickUpLocation.Street }}</td>
                  <td>{{ item.weight }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center display-1">Delivery Details</div>
          <v-simple-table dark>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Reciever Person</th>
                  <th class="text-left">Delivery Address</th>
                  <th class="text-left">Phone Number</th>
                  <th class="text-left">Distance Km</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.deliveryDetails.name }}</td>
                  <td>{{ item.deliveryDetails.Street }}</td>
                  <td>{{ item.deliveryDetails.mobile }}</td>
                  <td>{{ item.distance }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center ma-7">
            <ManuallyAssignToRIder :Package="item" />
          </div>
          {{ item.null }}
        </td>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="totalItems"></v-pagination>
    </div>
  </div>
</template>
<script>
import ManuallyAssignToRIder from "../components/AssignToRIder.vue";
import currencyFormatter from "currency-formatter";
export default {
  components: { ManuallyAssignToRIder },
  data() {
    return {
      page: 1,
      Currpage: 1,
      itemsPerPage: 10,
      dessertHeaders: [
        {
          text: "Tracking No",
          align: "start",
          value: "trackingNumber",
        },
        {
          text: "PickUp Date",
          align: "start",
          value: "pickUpDate",
        },
        { text: "Shipping Fee", align: "start", value: "shippingFee" },
        { text: "Request Type", align: "start", value: "RequestType" },
        { text: "Max KM", value: "maxKmReach" },
      ],
    };
  },
  computed: {
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    newPackages() {
      return this.$store.getters.newParcels;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    Approve(payload1) {
      let payload = {
        token: this.getToken,
        invoiceId: payload1._id,
        route: this.$router,
      };
      this.$store.dispatch("Approve", payload);
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    GetNewPackages() {
      let payload = {
        page: this.page,
        token: this.getToken,
      };
      this.$store.dispatch("GetNewParcel", payload);
    },
  },
  created() {
    this.GetNewPackages();
  },
  watch: {
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("GetNewParcel", payload);
        return;
      }
    },
  },
};
</script>

<style scoped>
.detailDev {
  width: 1000px;
  background-color: red;
  padding: 20px;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.maxKmNot {
  padding: 10px;
  background-color: green;
  color: white;
}
.maxKm {
  padding: 10px;
  background-color: red;
  color: white;
}
</style>